import React from 'react';

const CreativeArea = () => {
    return (
        <div className="creative-area">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="creative-inner-area">
                            <div className="row">
                                <div className="col-lg-6 col-sm-6 col-md-6">
                                    <div className="single-counter">
                                        <h3>1579</h3>
                                        <p>Completed Projects</p>
                                    </div>
                                </div>
        
                                <div className="col-lg-6 col-sm-6 col-md-6">
                                    <div className="single-counter">
                                        <h3>750</h3>
                                        <p>Happy Clients</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreativeArea;  